import { lazy, Suspense, useState } from "react"
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom"
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AdminHof from "src/components/Main/AdminHOF"
import { getCookie } from "./utils";
import { APPLICATION_TOKEN } from "./constants";

const Homepage = lazy(() => (import('src/components/Home')))
const Projects = lazy(() => (import('src/components/Projects')))
const Project = lazy(() => (import('src/components/Projects/Project')))
const Mentors = lazy(() => (import('src/components/Mentors')))
const Mentor = lazy(() => (import('src/components/Mentors/Mentor')))
const MentorCategories = lazy(() => (import('src/components/MentorCategories')))
const MentorCategory = lazy(() => (import('src/components/MentorCategories/MentorCategory')))
const ProjectCategories = lazy(() => (import('src/components/ProjectCategories')))
const ProjectCategory = lazy(() => (import('src/components/ProjectCategories/ProjectCategory')))
const PartnerCategories = lazy(() => (import('src/components/PartnerCategories')))
const PartnerCategory = lazy(() => (import('src/components/PartnerCategories/PartnerCategory')))
const PartnerSubCategories = lazy(() => (import('src/components/PartnerSubCategories')))
const PartnerSubCategory = lazy(() => (import('src/components/PartnerSubCategories/PartnerSubCategory')))
const Vcs = lazy(() => (import('src/components/Vcs')))
const Vc = lazy(() => (import('src/components/Vcs/Vc')))
const Partners = lazy(() => (import('src/components/Partners')))
const Partner = lazy(() => (import('src/components/Partners/Partner')))
const ProjectCohorts = lazy(() => (import('src/components/ProjectCohorts')))
const ProjectCohort = lazy(() => (import('src/components/ProjectCohorts/ProjectCohort')))
const Videos = lazy(() => (import('src/components/Videos')))
const Video = lazy(() => (import('src/components/Videos/Video')))
const Jobs = lazy(() => (import('src/components/Jobs')))
const Job = lazy(() => (import('src/components/Jobs/Job')))
const Notifications = lazy(() => (import('src/components/Notifications')))
const Notification = lazy(() => (import('src/components/Notifications/Notification')))
const VideoCategories = lazy(() => (import('src/components/VideoCategory')))
const VideoCategory = lazy(() => (import('src/components/VideoCategory/VideoCategory')))
const ExternalLinks = lazy(() => (import('src/components/ExternalLinks')))
const ExternalLink = lazy(() => (import('src/components/ExternalLinks/ExternalLink')))
const Events = lazy(() => (import('src/components/Events')))
const ServiceRequests = lazy(() => (import('src/components/ServiceRequest')))
const ServiceRequest = lazy(() => (import('src/components/ServiceRequest/Request')))
const Event = lazy(() => (import('src/components/Events/Event')))
const Feeds = lazy(() => (import('src/components/Feeds')))
const Feed = lazy(() => (import('src/components/Feeds/Feed')))
const LaunchNetworks = lazy(() => (import('src/components/LaunchNetwork')))
const LaunchNetwork = lazy(() => (import('src/components/LaunchNetwork/LaunchNetwork')))
const LaunchNetworkCategories = lazy(() => (import('src/components/LaunchNetworkCategories')))
const LaunchCategory = lazy(() => (import('src/components/LaunchNetworkCategories/LaunchCategory')))
const PartnerDiscounts = lazy(() => (import('src/components/PartnerDiscounts')))
const PartnerDiscount = lazy(() => (import('src/components/PartnerDiscounts/PartnerDiscount')))
const GrantPartners = lazy(() => (import('src/components/GrantPartners')))
const GrantPartner = lazy(() => (import('src/components/GrantPartners/GrantPartner')))
const Attendances = lazy(() => (import('src/components/Attendance')))
const Attendance = lazy(() => (import('src/components/Attendance/Attendance')))


const Login = lazy(() => (import('src/components/Auth/Login')))

const lazySuspense = <div className="lazy-suspense-loader"><h2>Loading Interface...</h2></div>

export default function App() {

  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#ffffff',
        dark: '#373737',
        light: "#FF0071"
      },
      secondary: {
        main: '#373737',
      },
      background: {
        default: '#09090A',
        paper: "#09090A"

      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Suspense fallback={lazySuspense}>
          <Routes>
            <Route path="/" element={<PrivateRoute><Homepage /></PrivateRoute>} />

            <Route path="/projects" element={<PrivateRoute><Projects /></PrivateRoute>} />
            <Route path="/project/view/:id" element={<PrivateRoute><Project /></PrivateRoute>} />
            <Route path="/project/:id" element={<PrivateRoute><Project /></PrivateRoute>} />

            <Route path="/mentors" element={<PrivateRoute><Mentors /></PrivateRoute>} />
            <Route path="/mentor/:id" element={<PrivateRoute><Mentor /></PrivateRoute>} />
            <Route path="/mentor/view/:id" element={<PrivateRoute><Mentor /></PrivateRoute>} />

            <Route path="/vcs" element={<PrivateRoute><Vcs /></PrivateRoute>} />
            <Route path="/vc/view/:id" element={<PrivateRoute><Vc /></PrivateRoute>} />
            <Route path="/vc/:id" element={<PrivateRoute><Vc /></PrivateRoute>} />

            <Route path="/partners" element={<PrivateRoute><Partners /></PrivateRoute>} />
            <Route path="/partner/view/:id" element={<PrivateRoute><Partner /></PrivateRoute>} />
            <Route path="/partner/:id" element={<PrivateRoute><Partner /></PrivateRoute>} />

            <Route path="/notifications" element={<PrivateRoute><Notifications /></PrivateRoute>} />
            <Route path="/notification/new" element={<PrivateRoute><Notification /></PrivateRoute>} />
            <Route path="/notification/:id" element={<PrivateRoute><Notification /></PrivateRoute>} />

            <Route path="/videos" element={<PrivateRoute><Videos /></PrivateRoute>} />
            <Route path="/video/new" element={<PrivateRoute><Video /></PrivateRoute>} />
            <Route path="/video/:id" element={<PrivateRoute><Video /></PrivateRoute>} />

            <Route path="/grantpartners" element={<PrivateRoute><GrantPartners /></PrivateRoute>} />
            <Route path="/grantpartner/new" element={<PrivateRoute><GrantPartner /></PrivateRoute>} />
            <Route path="/grantpartner/:id" element={<PrivateRoute><GrantPartner /></PrivateRoute>} />

            <Route path="/jobs" element={<PrivateRoute><Jobs /></PrivateRoute>} />
            <Route path="/job/new" element={<PrivateRoute><Job /></PrivateRoute>} />
            <Route path="/job/:id" element={<PrivateRoute><Job /></PrivateRoute>} />

            <Route path="/links" element={<PrivateRoute><ExternalLinks /></PrivateRoute>} />
            <Route path="/link/new" element={<PrivateRoute><ExternalLink /></PrivateRoute>} />
            <Route path="/link/:id" element={<PrivateRoute><ExternalLink /></PrivateRoute>} />

            <Route path="/events" element={<PrivateRoute><Events /></PrivateRoute>} />
            <Route path="/event/new" element={<PrivateRoute><Event /></PrivateRoute>} />
            <Route path="/event/:id" element={<PrivateRoute><Event /></PrivateRoute>} />

            <Route path="/feeds" element={<PrivateRoute><Feeds /></PrivateRoute>} />
            <Route path="/feed/new" element={<PrivateRoute><Feed /></PrivateRoute>} />
            <Route path="/feed/:id" element={<PrivateRoute><Feed /></PrivateRoute>} />

            <Route path="/requests" element={<PrivateRoute><ServiceRequests /></PrivateRoute>} />
            <Route path="/request/view/:id" element={<PrivateRoute><ServiceRequest /></PrivateRoute>} />

            <Route path="/attendances" element={<PrivateRoute><Attendances /></PrivateRoute>} />
            <Route path="/attendance/view/:id" element={<PrivateRoute><Attendance /></PrivateRoute>} />

            <Route path="/projectcohorts" element={<PrivateRoute><ProjectCohorts /></PrivateRoute>} />
            <Route path="/projectcohort/new" element={<PrivateRoute><ProjectCohort /></PrivateRoute>} />
            <Route path="/projectcohort/:id" element={<PrivateRoute><ProjectCohort /></PrivateRoute>} />

            <Route path="/launchnetworks" element={<PrivateRoute><LaunchNetworks /></PrivateRoute>} />
            <Route path="/launchnetwork/new" element={<PrivateRoute><LaunchNetwork /></PrivateRoute>} />
            <Route path="/launchnetwork/:id" element={<PrivateRoute><LaunchNetwork /></PrivateRoute>} />
            {/* <Route path="/launchnetwork/view/:id" element={<PrivateRoute><LaunchNetwork /></PrivateRoute>} /> */}

            <Route path="/launchcategories" element={<PrivateRoute><LaunchNetworkCategories /></PrivateRoute>} />
            <Route path="/launchcategory/new" element={<PrivateRoute><LaunchCategory /></PrivateRoute>} />
            <Route path="/launchcategory/:id" element={<PrivateRoute><LaunchCategory /></PrivateRoute>} />

            <Route path="/mentorCategories" element={<PrivateRoute><MentorCategories /></PrivateRoute>} />
            <Route path="/mentorCategory/new" element={<PrivateRoute><MentorCategory /></PrivateRoute>} />
            <Route path="/mentorCategory/:id" element={<PrivateRoute><MentorCategory /></PrivateRoute>} />

            <Route path="/projectcategories" element={<PrivateRoute><ProjectCategories /></PrivateRoute>} />
            <Route path="/projectcategory/new" element={<PrivateRoute><ProjectCategory /></PrivateRoute>} />
            <Route path="/projectcategory/:id" element={<PrivateRoute><ProjectCategory /></PrivateRoute>} />

            <Route path="/partnercategories" element={<PrivateRoute><PartnerCategories /></PrivateRoute>} />
            <Route path="/partnercategory/new" element={<PrivateRoute><PartnerCategory /></PrivateRoute>} />
            <Route path="/partnercategory/:id" element={<PrivateRoute><PartnerCategory /></PrivateRoute>} />

            <Route path="/partnersubcategories" element={<PrivateRoute><PartnerSubCategories /></PrivateRoute>} />
            <Route path="/partnersubcategory/new" element={<PrivateRoute><PartnerSubCategory /></PrivateRoute>} />
            <Route path="/partnersubcategory/:id" element={<PrivateRoute><PartnerSubCategory /></PrivateRoute>} />

            <Route path="/partnerdiscounts" element={<PrivateRoute><PartnerDiscounts /></PrivateRoute>} />
            <Route path="/partnerdiscount/new" element={<PrivateRoute><PartnerDiscount /></PrivateRoute>} />
            <Route path="/partnerdiscount/:id" element={<PrivateRoute><PartnerDiscount /></PrivateRoute>} />

            <Route path="/videocategories" element={<PrivateRoute><VideoCategories /></PrivateRoute>} />
            <Route path="/videocategory/new" element={<PrivateRoute><VideoCategory /></PrivateRoute>} />
            <Route path="/videocategory/:id" element={<PrivateRoute><VideoCategory /></PrivateRoute>} />

            <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="*" element={<div className="not-found-page">404 | Page Not Found.</div>} />
          </Routes>
        </Suspense>
      </Router>
    </ThemeProvider>
  )
}

function PrivateRoute({ children }) {
  const isLoggedIn = !!getCookie(APPLICATION_TOKEN)
  if (isLoggedIn) {
    return <AdminHof>
      {children}
    </AdminHof>
  } else {
    return <Navigate to="/login" />
  }
}

export const PublicRoute = ({ children }) => {
  const isLoggedIn = !!getCookie(APPLICATION_TOKEN)
  if (isLoggedIn) {
    return <Navigate to="/" />
  } else {
    return children
  }
}