import React from 'react';
import ReactDOM from 'react-dom/client';
import 'src/styles/index.scss';
import App from './routes';
import reportWebVitals from './reportWebVitals';
import { Toaster } from "react-hot-toast";
import { APPLICATION_BASE_URL } from './constants';
import Axios from "axios";

Axios.defaults.baseURL = APPLICATION_BASE_URL;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    <App />
    <Toaster
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        duration: 5000,
        style: {
          background: "#ffffff",
          color: "#363636",
          maxWidth: '100%',
          fontWeight: 'bold',
          fontSize: '14px'
        },
        // Default options for specific types
        success: {
          duration: 3000,
        },
      }}
    />
  </>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
