import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { useNavigate } from 'react-router-dom';

export const routeList = [
  {
    name: 'Dashboard',
    url: '/',
    Icon: <DashboardIcon />,
    activeRoutes: ['/'],
  },
  {
    name: 'Projects',
    url: '/projects',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/projects', '/project/view/:id', '/project/:id'],
  },
  {
    name: 'Mentors',
    url: '/mentors',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/mentors', '/mentor/view/:id', '/mentor/:id'],
  },
  {
    name: 'Partners',
    url: '/partners',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/partners', '/partner/view/:id'],
  },
  {
    name: 'Grant Partners',
    url: '/grantpartners',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/grantpartners', '/grantpartner/:id', '/grantpartner/new'],
  },
  {
    name: 'Vcs',
    url: '/vcs',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/vcs', '/vc/view/:id'],
  },
  {
    name: 'Launch Network',
    url: '/launchnetworks',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/launchnetworks', '/launchnetwork/:id', '/launchnetwork/new'],
  },
  {
    name: 'Videos',
    url: '/videos',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/videos', '/video/:id', '/video/new'],
  },
  {
    name: 'External Links',
    url: '/links',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/links', '/link/:id', '/link/new'],
  },
  {
    name: 'Attendance',
    url: '/attendances',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/attendances', '/attendance/view/:id']
  },
  {
    name: 'Events',
    url: '/events',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/events', '/event/:id', '/event/new'],
  },
  {
    name: 'Jobs',
    url: '/jobs',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/jobs', '/job/:id', '/job/new'],
  },
  {
    name: 'Notifications',
    url: '/notifications',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/notifications', '/notification/:id', '/notification/new'],
  },
  {
    name: 'Service Request',
    url: '/requests',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/requests', '/request/view/:id', 'request/:id'],
  },
  {
    name: 'Feeds',
    url: '/feeds',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/feeds', '/feed/:id', '/feed/new'],
  },
  {
    name: 'LN Categories',
    url: '/launchcategories',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/launchcategories', '/launchcategory/:id', '/launchcategory/new'],
  },
  {
    name: 'Mentor Categories',
    url: '/mentorCategories',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/mentorCategories', '/mentorCategory/:id', '/mentorCategory/new'],
  },
  {
    name: 'Project Categories',
    url: '/projectcategories',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/projectcategories', '/projectcategory/:id', '/projectcategory/new'],
  },
  {
    name: 'Project Cohort',
    url: '/projectcohorts',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/projectcohorts', '/projectcohort/:id', '/projectcohort/new'],
  },
  {
    name: 'Partner Discount',
    url: '/partnerdiscounts',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/partnerdiscounts', '/partnerdiscount/:id', '/partnerdiscount/new'],
  },
  {
    name: 'Partner Categories',
    url: '/partnercategories',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/partnercategories', '/partnercategory/:id', '/partnercategory/new'],
  },
  {
    name: 'Partner SubCategories',
    url: '/partnersubcategories',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/partnersubcategories', '/partnersubcategory/:id', '/partnersubcategory/new'],
  },
  {
    name: 'Video Categories',
    url: '/videocategories',
    Icon: <PlaylistAddIcon />,
    activeRoutes: ['/videocategories', '/videocategory/:id', '/videocategory/new'],
  },
]

export default function MainListItems(props: { selectedRoute: any }) {
  const { selectedRoute = {} } = props
  const history = useNavigate()
  return <React.Fragment>
    {
      routeList.map((route, index) => {
        const isActive = route.url === selectedRoute.url
        return <ListItemButton
          key={index}
          selected={isActive}
          onClick={() => history(route.url)}
          sx={[
            {
              '&.Mui-selected': {
                background: (t) => t.palette.mode === "dark" ? t.palette.primary.dark : t.palette.primary.light
              },
              '&.Mui-selected:hover': {
                background: (t) => t.palette.mode === "dark" ? t.palette.primary.dark : t.palette.primary.light
              },
            }
          ]}
        >
          <ListItemIcon>
            {route.Icon}
          </ListItemIcon>
          <ListItemText primary={route.name} />
        </ListItemButton>
      }
      )}
  </React.Fragment>
}