import { useState, useCallback } from "react";
import axios from "axios";
import { getCookie } from "src/utils";
import { APPLICATION_TOKEN } from "src/constants";

export const getAuthToken = () => {
  let authorization = getCookie(APPLICATION_TOKEN) || "";
  authorization = "Bearer " + authorization;
  return authorization;
};

export default function useFetch(endPoint: string) {
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");

  const getRequest = async (payload = {}): Promise<any> => {
    setLoading(true);
    try {
      const data = await axios.get(endPoint, { params: payload, headers: { authorization: getAuthToken() } })
      setData(data.data);
      setLoading(false);
      return [data.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data]
    }
  };

  const getRequestById = async (id: string, payload = {}): Promise<[data: any, error: any]> => {
    setLoading(true);
    try {
      const data = await axios.get(`${endPoint}/${id}`, { params: payload, headers: { authorization: getAuthToken() } });
      setData(data.data);
      setLoading(false);
      return [data.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data || { message: "Something went wrong" }]
    }
  };

  const postRequestById = async (id: string, payload = {}): Promise<[data: any, error: any]> => {
    setLoading(true);
    try {
      const data = await axios.post(`${endPoint}/${id}`, payload, { headers: { authorization: getAuthToken() } });
      setData(data.data);
      setLoading(false);
      return [data.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data || { message: "Something went wrong" }]
    }
  };

  const postRequest = async (payload = {}, query?): Promise<[data: any, error: any]> => {
    setLoading(true);
    try {
      const data = await axios.post(endPoint, payload, { headers: { authorization: getAuthToken() }, params: query || {} });
      setData(data?.data);
      setLoading(false);
      return [data?.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data || { message: "Something went wrong" }]
    }
  };

  const updateRequest = async (id: string, payload = {}): Promise<[data: any, error: any]> => {
    setLoading(true);
    try {
      const data = await axios.put(`${endPoint}/${id}`, payload, { headers: { authorization: getAuthToken() } });
      setData(data.data);
      setLoading(false);
      return [data.data, null]
    } catch (err: any) {
      setLoading(false);
      return [null, err?.response?.data || { message: "Something went wrong" }]
    }
  };

  const deleteRequest = async (id: string): Promise<[data: any, error: any]> => {
    setLoading(true);
    setDeleteId(id);
    try {
      const data = await axios.delete(`${endPoint}/${id}`, { headers: { authorization: getAuthToken() } });
      setDeleteId("");
      setData(data.data);
      setLoading(false);
      return [data.data, null]
    } catch (err: any) {
      setDeleteId("");
      setLoading(false);
      return [null, err?.response?.data || { message: "Something went wrong" }]
    }
  };

  const uploadImageRequest = async (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post("/api/v1/admin/upload", formData, { headers: { authorization: getAuthToken() } })
        .then(({ data = {} }: any) => {
          const { data: innerData = {} } = data;
          const { Location = "" } = innerData;
          resolve({ data: { link: Location } });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  return { data, loading, getRequest, postRequest, postRequestById, getRequestById, updateRequest, deleteRequest, uploadImageRequest, deleteId };
}
